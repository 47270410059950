"use client"

import { BubbleMenu } from "@tiptap/react"
import { Editor } from "@tiptap/core"

import ToolbarButton from "../ToolbarButton"
import { hideOnEsc } from "../../../util/tippy"
import { TbRectangle, TbTrash } from "react-icons/tb"
import { RiLinkM } from "react-icons/ri"
import { MdInsertChartOutlined } from "react-icons/md"

type Props = {
  editor: Editor
}
export default function EmbedToolbar({ editor }: Props): JSX.Element {
  function shouldShow() {
    return ({ editor, view, state, oldState, from, to }: any) => {
      if (!view.hasFocus() || state.selection.empty) return false

      if (state.selection?.node?.type?.name === "embedly") return true
      return false
    }
  }

  const data = editor.getAttributes("embedly").data
    ? JSON.parse(editor.getAttributes("embedly").data)
    : null

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="embedToolbar"
      shouldShow={shouldShow()}
      tippyOptions={{
        maxWidth: "600px",
        plugins: [hideOnEsc],

        duration: [100, 150],
        animation: "shift-away",
        inertia: true,
      }}
      className="bg-gray-100 text-lg rounded-sm shadow-lg"
    >
      <ToolbarButton
        icon={<RiLinkM />}
        altText="Set plain link"
        onClick={() => editor.chain().focus().setFormat("link").run()}
      />
      <ToolbarButton
        icon={<TbRectangle className="p-[0.2rem]" />}
        altText="Set small link preview"
        onClick={() => editor.chain().focus().setFormat("small").run()}
        isActive={editor.getAttributes("embedly").format === "small"}
      />
      {data?.html && (
        <ToolbarButton
          icon={<MdInsertChartOutlined />}
          altText="Set iframe embed"
          onClick={() => editor.chain().focus().setFormat("iframe").run()}
          isActive={editor.getAttributes("embedly").format === "iframe"}
        />
      )}
      <ToolbarButton
        icon={<TbTrash />}
        altText="Delete embed"
        onClick={() => editor.chain().focus().deleteSelection().run()}
      />
    </BubbleMenu>
  )
}
