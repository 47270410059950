import { BubbleMenu } from "@tiptap/react"
import { Editor } from "@tiptap/core"

import ToolbarButton from "../ToolbarButton"
import { hideOnEsc } from "../../../util/tippy"
import {
  TbFloatLeft,
  TbFloatCenter,
  TbFloatRight,
  TbDownload,
  TbTrash,
} from "react-icons/tb"
import { isPlaceholderImage } from "../Image/constants"
import { RiLinkM } from "react-icons/ri"
import { MutableRefObject } from "react"

type Props = {
  editor: Editor
  imageToolbar: MutableRefObject<string>
  linkToolbar: MutableRefObject<string>
}
export default function ImageToolbar({
  editor,
  imageToolbar,
  linkToolbar,
}: Props): JSX.Element {
  function shouldShow() {
    return ({ editor, view, state, oldState, from, to }: any) => {
      if (!view.hasFocus() || state.selection.empty) return false

      const nodeType = state.selection?.node?.type?.name

      if (
        nodeType === "image" &&
        (state.selection?.node?.content?.firstChild?.type?.name ===
          "imageLink" ||
          !isPlaceholderImage(state.selection?.node?.attrs?.src))
      )
        return true

      return false
    }
  }

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="textToolbar"
      shouldShow={shouldShow()}
      tippyOptions={{
        maxWidth: "600px",
        plugins: [hideOnEsc],

        duration: [100, 150],
        animation: "shift-away",
        inertia: true,
      }}
      className="bg-gray-100 text-lg rounded-sm shadow-lg"
    >
      <ToolbarButton
        icon={<TbFloatLeft />}
        altText="Float image left"
        onClick={() => editor.chain().focus().setFloat("left").run()}
        isActive={editor.getAttributes("figure").float === "left"}
      />
      <ToolbarButton
        icon={<TbFloatCenter />}
        altText="Center image"
        onClick={() => editor.chain().focus().setFloat("none").run()}
        isActive={editor.getAttributes("figure").float === "none"}
      />
      <ToolbarButton
        icon={<TbFloatRight />}
        altText="Float image right"
        onClick={() => editor.chain().focus().setFloat("right").run()}
        isActive={editor.getAttributes("figure").float === "right"}
      />
      <ToolbarButton
        icon={<RiLinkM />}
        altText="Link"
        onClick={() => {
          imageToolbar.current = "hide"
          linkToolbar.current = "show"
          editor.chain().focus().run()
          // HACK: I don't love this, but it works for now...
          setTimeout(() => {
            imageToolbar.current = "show"
            linkToolbar.current = "hide"
          }, 200)
        }}
        isActive={editor.getAttributes("imageLink").href}
        isGroupStart={false}
      />
      <ToolbarButton
        icon={<TbDownload />}
        altText="Download image"
        onClick={() => window.open(editor.getAttributes("image").src)}
      />
      <ToolbarButton
        icon={<TbTrash />}
        altText="Delete image"
        onClick={() => editor.chain().focus().deleteSelection().run()}
      />
    </BubbleMenu>
  )
}
