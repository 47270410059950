export const hideOnEsc = {
  name: "hideOnEsc",
  defaultValue: true,
  fn({ hide }: any) {
    function onKeyDown(event: any) {
      if (event.keyCode === 27) {
        hide()
      }
    }

    return {
      onShow() {
        document.addEventListener("keydown", onKeyDown)
      },
      onHide() {
        document.removeEventListener("keydown", onKeyDown)
      },
    }
  },
}

const hideOnPopperBlur = {
  name: "hideOnPopperBlur",
  defaultValue: true,
  fn(instance: any) {
    return {
      onCreate() {
        instance.popper.addEventListener("focusout", (event: any) => {
          if (
            instance.props.hideOnPopperBlur &&
            event.relatedTarget &&
            !instance.popper.contains(event.relatedTarget)
          ) {
            instance.hide()
          }
        })
      },
    }
  },
}
